.cars {
  background-color: rgb(234, 234, 234);
  padding-top: 100px;
}

.cars .car-list {
  padding: 10px 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
}

.car-item {
  width: 340px;
  height: 490px;
  margin: 40px;
  background-color: rgba(239, 239, 239, 0.436);
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* justify-content: space-between; */
  box-shadow: 0 24px 48px rgba(36, 36, 59, 0.4);
  transition: transform 0.3s, background-color 0.5s;
}

.car-pic {
  width: 120%;
  height: 55%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.car-pic:hover {
  transform: scale(1.1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.car-info {
  width: 100%;
  height: 25%;
}

.info-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.model {
  font-size: 22px;
  font-weight: 600;
  color: #5f5f5f;
  padding: 0 15px;
  margin: 0;
}

.capacity {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  padding: 0 15px;
  margin: 10px 0 0;
}

.price {
  font-size: 30px;
  font-weight: 400;
  color: #5f5f5f;
  padding: 0 15px;
  margin: 10px 0 0;
}
