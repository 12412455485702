.home {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: url("../../utils/images/home_bg_image.png");
  background-size: cover;
  background-position: center;
  color: #fff;
}

.home h1 {
  color: #d4d4d4;
}

.home p {
  color: #666;
  font-size: 18px;
}

.header {
  height: 10vh;
  width: 90vw;
  margin-top: 20px;
  border-radius: 15px;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.044),
      transparent
    ),
    linear-gradient(to left, rgba(255, 255, 255, 0.044), transparent);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header .nav {
  width: 50vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header .nav-button {
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  color: #dfdfdf;
}

.nav-button:hover {
  transform: scale(1.2);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  color: rgb(255, 255, 255);
}

.car-container {
  height: 55vh;
  width: 90vw;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
}

.shadow {
  height: 55vh;
  width: 45vw;
  position: absolute;
  box-shadow: 53px 0px 150px 5px rgba(210, 210, 210, 0.68);
  -webkit-box-shadow: 53px 0px 150px 5px rgba(210, 210, 210, 0.68);
  -moz-box-shadow: 53px 0px 150px 5px rgba(130, 29, 29, 0.68);
}

.sprinter {
  height: 65vh;
  width: 70vw;
  background-image: url("../../utils/cars/LUX_Sprinter.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 11;
  top: 16px;
  left: -5vw;
}

.text {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.044),
      transparent
    ),
    linear-gradient(to left, rgba(255, 255, 255, 0.044), transparent);
  align-self: flex-end;
  font-size: 1vw;
  width: 27vw;
  padding: 1px 40px;
  border-radius: 15px;
}

/* Responsive Styling */

@media only screen and (max-width: 962px) {
  .home {
    justify-content: unset;
  }

  .header {
    width: 86vw;
    padding: 2vw;
  }

  .text {
    width: 88vw;
    padding: 1vw;
    font-size: 1.3vw;
    margin-top: 20px;
  }

  .car-container {
    position: static;
    display: block;
    height: auto;
  }

  .sprinter {
    width: auto;
    position: static;
  }

  .header .nav {
    width: 90vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media only screen and (max-width: 652px) {
  .home {
    height: 90vw;
  }

  .header .nav-button {
    font-size: 4vw;
  }

  .sprinter {
    height: 60vw;
    position: static;
  }

  .text {
    font-size: 1.6vw;
  }
}
