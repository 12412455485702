.car-item {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bookButton {
  width: 60%;
  margin: auto;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #808080;
  color: #fff;
  background-color: #808080;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect */
.bookButton:hover {
  background-color: #5f5f5f;
}

/* Active state */
.bookButton:active {
  background-color: #424242;
}
