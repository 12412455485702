.aboutUs {
  background-color: rgb(234, 234, 234);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
}

.about-contnet {
  width: 82vw;
  background-color: rgba(239, 239, 239, 0.436);
  border-radius: 28px;
  box-shadow: 0 24px 48px rgba(36, 36, 59, 0.4);
  padding: 40px 0;
}

.about-contnet .about-text {
  font-size: 25px;
  font-weight: 600;
  margin: 0 50px;
  color: #5f5f5f;
}

.award-box {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
}

.award-box .award-item {
  margin: 0 50px;
}

.award-item h2 {
  color: #5f5f5f;
  font-size: 60px;
  margin-bottom: 0;
}

.award-item p {
  color: #5f5f5f;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .award-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 0;
  }

  .about-contnet .about-text {
    font-size: 4.2vw;
  }
}
